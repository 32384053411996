import React, { useEffect, useState } from "react";
import { format, subMonths } from "date-fns";
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  CartesianGrid,
} from "recharts";
import Dropdown from "./Dropdown";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const options = [
  { value: "3", label: "3 Months" },
  { value: "6", label: "6 Months" },
  { value: "12", label: "1 Year" },
  { value: "Custom", label: "Custom" },
];

export const GoldPriceChart = () => {
  const [selectedOption, setSelectedOption] = useState(options[2].value);

  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
  };
  const [chartData, setChartData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option === "Custom") {
      setShowModal(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const startDateValue =
        selectedOption !== "Custom"
          ? subMonths(new Date(), parseInt(selectedOption || "0"))
          : startDate;
      const endDateValue = selectedOption !== "Custom" ? new Date() : endDate;

      const formattedStartDate = format(startDateValue, "yyyy-MM-dd");
      const formattedEndDate = format(endDateValue, "yyyy-MM-dd");

      const apiUrl = `https://api.metalpriceapi.com/v1/timeframe?api_key=a10e464db091daece4393b856b5faaef&start_date=${formattedStartDate}&end_date=${formattedEndDate}&base=XAU&currencies=${selectedCurrency}`;

      try {
        const response = await fetch(apiUrl);
        const responseData = await response.json();
        const rates = responseData.rates;
        // convert date to Format DD Month YY
        const filteredData = Object.keys(rates).map((date) => ({
          date: format(new Date(date), "dd MMM yy"),
          price: rates[date][selectedCurrency],
        }));

        setChartData(filteredData);
      } catch (error) {
        console.error("Error fetching gold price data:", error);
      }
    };

    fetchData();
  }, [selectedOption, selectedCurrency, startDate, endDate]);
  const handleDateChange = (date, dateType) => {
    if (dateType === "start") {
      setStartDate(date);
    } else if (dateType === "end") {
      if (date < startDate) {
        return;
      }
      setEndDate(date);
    }
  };
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="gap-x-29 flex gap-5 p-4">
          <Dropdown
            heading="Select Duration"
            options={options}
            onSelect={handleOptionSelect}
          />
          <Dropdown
            heading="Select Currency"
            options={[
              { label: "USD", value: "USD" },
              { label: "EUR", value: "EUR" },
              { label: "GBP", value: "GBP" },
            ]}
            onSelect={handleCurrencySelect}
          />
        </div>
      </div>
      <ResponsiveContainer width={"100%"} height={500} className={"text-sm"}>
        <AreaChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="price"
            stroke="#8884d8"
            fill="#8884d8"
          />
        </AreaChart>
      </ResponsiveContainer>
      {showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform  rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Select Date Range
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500"></p>
                </div>

                <div className="mt-5">
                  <label
                    className="mb-2 block text-sm font-bold text-gray-700"
                    htmlFor="from-date"
                  >
                    From Date
                  </label>
                  <DateTimePicker
                    id="from-date"
                    onChange={(date) => handleDateChange(date, "start")}
                    value={startDate}
                    className={"text-sm"}
                  />
                </div>

                <div className="mt-5">
                  <label
                    className="mb-2 block text-sm font-bold text-gray-700"
                    htmlFor="end-date"
                  >
                    End Date
                  </label>
                  <span>
                    <DateTimePicker
                      id="end-date"
                      onChange={(date) => handleDateChange(date, "end")}
                      value={endDate}
                      className={"text-sm "}
                    />
                  </span>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowModal(false)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const GoldPricePage = () => {
  return (
    <div>
      <h1 className="font-bold text-3xl text-yellow-500 mt-8">
        Gold Price Chart
      </h1>
      <GoldPriceChart />
    </div>
  );
};
